import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.fr'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.es'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.en-GB'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.nl'

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

const language = typeof current_language_code !== 'undefined' ? current_language_code.toLowerCase() : 'fr'

// Configuration par défaut de la datepicker
const datepickerOptions = {
  autoclose: true,
  language,
  startDate: startDate('2025-04-05'),
  endDate: new Date('2025-09-28'),
  todayHighlight: true,
  format: 'dd/mm/yyyy',
  disableTouchKeyboard: true
}

const dateForceWeek = {
  start: new Date('2025-06-28'),
  end: new Date('2022-08-30')
}

function startDate(strDate) {
  const nowDate = new Date()
  let startDate = new Date(strDate)
  if (startDate < nowDate) {
    startDate = nowDate.addDays(1)
  }

  return startDate
}

// Vérifie si un utilisateur est connécté
const isLoggedIn = document.getElementsByTagName('body')[0].className.match(/logged\-in/)

// Conversion de px en rem
const __rem = (valueInPixel) => `${parseFloat(valueInPixel) / 16}rem`

export {
  language,
  datepickerOptions,
  dateForceWeek,
  isLoggedIn,
  __rem
}
